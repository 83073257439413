import {
  Box,
  Flex,
  Image,
  useToast,
  Center,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/BaseContainer";
import { DropdownInput } from "../../components/form/controls/DropdownInput";
import { FormInput } from "../../components/form/controls/FormInput";
import { TextAreaInput } from "../../components/form/controls/TextAreaInput";
import { Loading } from "../../components/shared/Loading";
import { Separator } from "../../components/shared/Separator";
import { SupportService } from "../../service/support.service";
import { store } from "../../store";

const baseContainerStyles = {
  flexGrow: "1",
  borderRadius: { base: "0px", lg: "8px 8px 0 0" },
  minW: "95%",
  paddingBottom: "50px",
};

const supportService = new SupportService();

export const Support: React.FC<any> = (props: any) => {
  const toast = useToast();

  const userEmail = store.getState().userProfile.email;

  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const [formState, setFormState] = useState({});

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const init = async () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { ...formState, email: userEmail };
    setSending(true);
    await supportService
      .send(payload)
      .then(() => {
        setSendSuccess(true);
        setFormState({});
      })
      .catch(() => alert("error"))
      .finally(() => setSending(false));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout style={{ backgroundColor: "brand.200" }}>
      <Loading loading={loading} />

      <Box bg="brand.200" m="2.5%" mb="0" borderRadius="6px">
        <Flex color="white" my="20px" flexDir="column" gap="6px">
          <Center>
            <Flex flexDir="row" gap="12px">
              <Image
                src="/assets/company-logo/sumlookup.png"
                alt="Sumlookup Branding"
                width="260px"
                height="40px"
                mt="20px"
              />
              <Box fontSize="38px" fontWeight="400" mt="10px">
                SUPPORT
              </Box>
            </Flex>
          </Center>
          <Center>
            <Box
              className="text-lg"
              mt="10px"
              textAlign="center"
              w="60%"
              fontSize="18px"
              fontWeight="400"
            >
              We are committed to providing an exceptional platform experience
              for all our clients. We constantly strive to enhance our
              platform's functionality and features to ensure your integrations
              are robust and reliable. Should you encounter any concerns, have
              valuable suggestions, or seek clarification, our team at SUMLOOKUP
              will be happy to assist you.
              <br />
              <br />
              <Box fontWeight="700">- SUMLOOKUP Team</Box>
            </Box>
          </Center>
        </Flex>
      </Box>
      <BaseContainer styles={baseContainerStyles}>
        <Flex flexDir="row" gap="12px">
          <Box
            p="12px"
            w="60%"
            color="brand.100"
            borderRight="1px solid brand.200"
          >
            <Flex flexDir="column" gap="6px">
              <Box fontSize="22px" fontWeight="700">
                Frequently asked questions
                <Separator />
              </Box>
              <QandA
                question="What is the Lighthouse used for?"
                answer="The Lighthouse aims to provide our clients with as much visibility as possible when it comes to their integrations."
              />
              <QandA
                question="How do I trigger an integration?"
                answer="On the sidebar, you will see a section 'Manual Trigger'. On that page, you will see a list of all your active integrations and their last run status. Click 'Run' to trigger an integration. Please ensure you are aware of the integration and how it works before triggering it."
              />
              <QandA
                question="What are 'Archives & Logs'?"
                answer="In order to provide as much visibility as possible, we have made the most recently uploaded (to Anaplan) file, and any error logs available for download. The user can directly see how the file was transformed and loaded, as well as debug any error logs."
              />
              <QandA
                question="What information is available in the 'Dashboard'?"
                answer="'Dashboard' gives the handy analytics of all your integrations in one place. The stats are dated to the first of the current month to now. Please keep in mind we are actively working on adding more analytics and features. "
              />
            </Flex>
          </Box>
          <Box w="40%" pr="20px">
            <Box p="12px" fontSize="22px" fontWeight="700">
              Get in touch!
              <Separator />
            </Box>

            <form onSubmit={handleSubmit}>
              <Flex p="12px" flexDirection="column" gap="12px">
                <FormInput
                  name="name"
                  title="Name"
                  placeholder="Your name"
                  required
                  onChange={onInputChange}
                ></FormInput>
                <DropdownInput
                  name="type"
                  title="Type"
                  required
                  options={{
                    feature: "Feature Request",
                    bug: "Bug Report",
                    feedback: "Feedback",
                  }}
                  defaultValue="feature"
                />
                <FormInput
                  name="subject"
                  title="Subject"
                  placeholder="What is this about?"
                  required
                  onChange={onInputChange}
                ></FormInput>
                <TextAreaInput
                  name="message"
                  title="Message"
                  placeholder="Your messsage"
                  required
                  onChange={onInputChange}
                ></TextAreaInput>
              </Flex>
              <Button
                hidden={sendSuccess}
                isDisabled={sending}
                w="20%"
                type="submit"
                ml="12px"
                mt="12px"
                color="white"
                bg="brand.red"
              >
                Send
              </Button>
              <Box
                hidden={!sendSuccess}
                p="12px"
                fontSize="18px"
                fontWeight="700"
              >
                Thanks - we'll be in touch soon!
              </Box>
            </form>
          </Box>
        </Flex>
      </BaseContainer>
    </Layout>
  );
};

const QandA = ({ question, answer }) => {
  return (
    <Flex flexDir="column" gap="6px" my="12px">
      <Box fontSize="18px" fontWeight="700">
        Q: {question}
      </Box>
      <Box fontSize="16px" fontWeight="400">
        {answer}
      </Box>
    </Flex>
  );
};
