import { QueryParams } from "../models/github/query-params.model";
import { WorkflowRun } from "../models/github/workflow-run.model";
import { Workflow } from "../models/github/workflow.model";
import { BaseService } from "./base.service";

export class IntegrationService extends BaseService {
  constructor() {
    super();
  }

  async triggerWorkflow(workflowId: string, runMonth?: string) {
    await this.client.post(`/integration/workflow/trigger`, { workflowId, runMonth });
  }

  async getWorkflows(): Promise<Workflow[]> {
    const resp = await this.client.get("/integration/workflow/list");
    return resp.data;
  }

  async getWorkflowCount(): Promise<any> {
    const resp = await this.client.get("/integration/workflow/list/count");
    return resp.data;
  }

  // returns workflows with latest runs
  async getWorkflowsWithRuns(query?: QueryParams) {
    const resp = await this.client.post("/integration/workflow/list/runs", {
      query,
    });
    return resp.data;
  }

  async getWorkflowRunsHistory(workflowId: string): Promise<WorkflowRun[]> {
    const resp = await this.client.get(
      `/integration/workflow/run/history?workflowId=${workflowId}`
    );
    return resp.data;
  }

  async getWorkflowRunLog(data: { integrationName: string, workflowId: string, workflowRunId: string }) {
    const resp = await this.client.post(
      `/integration/workflow/run/log`, data
    );

    return resp.data;
  }

  async getRepositoryFileList(repo: string, workflow: string) {
    const resp = await this.client.post(`/integration/workflow/backup/list`, {
      repo,
      workflow,
    });
    return resp.data;
  }

  async getArchiveFileDownloadLink(
    repo: string,
    workflow: string,
    fileName: string
  ) {
    const resp = await this.client.post(`/integration/workflow/backup/link`, {
      repo,
      workflow,
      fileName,
    });

    return resp.data;
  }
  
}
