"use client";

import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../third-party/shadcn/card";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../third-party/shadcn/chart";
import { IntegrationItem } from "@/src/models/integration-item.model";
import { useEffect, useState } from "react";

interface Props {
  data: IntegrationItem[];
}

const chartConfig = {
  attempts: {
    label: "Attempts",
  },
  Success: {
    label: "Success",
    color: "#000000",
  },
  Failed: {
    label: "Failed",
    color: "#0000FF",
  },
} satisfies ChartConfig;

export const IntegrationPieChart: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [chartProps, setChartProps] = useState<
    { result: string; attempts: number; fill: string }[]
  >([]);

  const init = () => {
    const sluChartData = [
      {
        result: "Success",
        attempts: data.filter((x) => x.conclusion !== "failure").length,
        fill: "#007B7F",
        // stroke: "rgba(54, 162, 235, 1)",
      },
      {
        result: "Failed",
        attempts: data.filter((x) => x.conclusion === "failure").length,
        fill: "#D76D3D",
        // stroke: "rgba(255, 99, 132, 1)",
      },
    ];
    setChartProps(sluChartData);
  };
  useEffect(() => {
    init();
  }, [data]);

  const successfulRuns = data.filter((x) => x.conclusion === "success").length;
  const totalRuns = data.filter((x) => x.conclusion).length;

  return !chartProps ? (
    <>Loading...</>
  ) : (
    <>
      <CardHeader className="items-center pb-0">
        <CardTitle>Integration Runs</CardTitle>
        <CardDescription>
          01 {new Date().toLocaleString("default", { month: "long" })} - current
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartProps}
              dataKey="attempts"
              nameKey="result"
              innerRadius={55}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalRuns.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          Runs
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            {/* <ChartLegend content={<ChartLegendContent nameKey="result" />} /> */}
            <PieChart accessibilityLayer />
          </PieChart>
        </ChartContainer>
        <div className="flex flex-row justify-evenly pb-4">
          <div className="flex flex-row items-center gap-2">
            <div className="text-sm font-medium">Success</div>
            <div className="h-3 w-6 bg-[#007B7F] "></div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="text-sm font-medium">Failed</div>
            <div className="h-3 w-6 bg-[#D76D3D] "></div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="flex items-center gap-2 font-medium leading-none text-center">
          Over {Math.floor((successfulRuns / totalRuns) * 100)}% success this
          month
        </div>
        <div className="leading-2 text-muted-foreground text-center ">
          Showing total integration runs for this month to date
        </div>
      </CardFooter>
    </>
  );
};
