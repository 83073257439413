import { cn } from "../../../lib/utils";
import React from "react";

const BaseContainer: React.FC<any> = (props: any) => {
  const { children, styles, className } = props;

  return (
    <div
      className={cn(`
        md:w-full
        min-w-[95%]
        w-full md:max-w-[640px]
        mt-0 md:mt-6
        mx-4 md:mx-auto
        p-6
        rounded-lg
        drop-shadow-md
      `,
      className
      )}
      {...styles}
    >
      <div>{children}</div>
    </div>
  );
};

export default BaseContainer;
