"use client";

import * as React from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../third-party/shadcn/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../third-party/shadcn/chart";
import { useEffect, useState } from "react";

const chartConfig = {
  runTime: {
    label: "runTime",
  },
} satisfies ChartConfig;

function generateSequentialArray(): number[] {
  const today = new Date();
  const dayOfMonth = today.getUTCDate();

  const arr = Array.from({ length: dayOfMonth }, (_, i) => i + 1);
  return arr;
}

function parseDay(dateString: string): number {
  const date = new Date(dateString);
  return date.getUTCDate();
}

function calculateAverage(arr: number[]): number {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
}

function transformData(items: any[]): any[] {
  const groupedData: { [key: string]: { [key: number]: number[] } } = {};

  for (const item of items) {
    const day = parseDay(item.lastRunDate);

    if (!groupedData[item.friendlyName]) {
      groupedData[item.friendlyName] = { [day]: [item.duration] };
    } else if (!groupedData[item.friendlyName][day]) {
      groupedData[item.friendlyName][day] = [item.duration];
    } else {
      groupedData[item.friendlyName][day].push(item.duration);
    }
  }

  const result = [];
  const today = new Date().getUTCDate();

  for (const friendlyName in groupedData) {
    const data = [];

    for (let day = 1; day <= today; day++) {
      if (groupedData[friendlyName][day]) {
        const averageTime = calculateAverage(groupedData[friendlyName][day]);
        data.push(averageTime);
      } else {
        data.push(0);
      }
    }

    const colors = getColors();
    result.push({ label: friendlyName, data, ...colors });
  }

  return result;
}

function getColors() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Create color strings
  const borderColor = `rgb(${r}, ${g}, ${b})`;
  const backgroundColor = `rgba(${r}, ${g}, ${b}, 0.5)`;

  return { borderColor, backgroundColor };
}

interface ChartDataPoint {
  day: string;
  [key: string]: number | string;
}

export function RunTime(props) {
  const { data } = props;
  const [chartProps, setChartProps] = useState<ChartDataPoint[]>([]);
  console.log(data);
  const init = () => {
    if (!data?.length) {
      return;
    }

    const labels = generateSequentialArray();
    const transformedData = transformData(data);
    console.log(transformedData);

    const chartData = labels.map((label, index) => {
      const dataPoint: ChartDataPoint = { day: label.toString() };
      transformedData.forEach((dataset) => {
        dataPoint[dataset.label] = dataset.data[index];
      });
      return dataPoint;
    });

    setChartProps(chartData);
  };

  useEffect(() => {
    init();
  }, [data]);

  console.log(chartProps);

  return (
    <Card>
      <CardHeader className="flex flex-col items-stretch space-y-0 p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle>Run Time - Average</CardTitle>
          <CardDescription>
            Showing daily average run time for this month (
            {new Date().toLocaleString("default", { month: "long" })})
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="px-2 sm:pb-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[350px] w-full"
        >
          <LineChart
            accessibilityLayer
            data={chartProps}
            margin={{
              left: 12,
              right: 12,
              top: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="day"
              tickLine={true}
              axisLine={true}
              tickMargin={8}
              minTickGap={32}
              label={{ value: "Day of Month", position: "bottom", offset: 0 }}
            />
            <YAxis
              tickLine={true}
              label={{ value: "time (s)", angle: -90, position: "insideLeft" }}
            />
            <ChartTooltip
              content={<ChartTooltipContent className="w-[200px]" />}
            />
            <ChartLegend verticalAlign="top" height={36} />
            {chartProps &&
              chartProps.length > 0 &&
              Object.keys(chartProps[0])
                .filter((key) => key !== "day")
                .map((key, index) => {
                  const { borderColor } = getColors();
                  return (
                    <Line
                      key={index}
                      dataKey={key}
                      type="linear"
                      stroke={borderColor}
                      strokeWidth={2}
                      fill={borderColor}
                    />
                  );
                })}
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
// "use client";
// import { TrendingUp } from "lucide-react";
// import { CartesianGrid, Line, LineChart, XAxis } from "recharts";

// import {
//   Card,
//   CardContent,
//   CardDescription,
//   CardFooter,
//   CardHeader,
//   CardTitle,
// } from "../../../third-party/shadcn/card";
// import {
//   ChartConfig,
//   ChartContainer,
//   ChartTooltip,
//   ChartTooltipContent,
// } from "../../../third-party/shadcn/chart";
// const chartData = [
//   { month: "January", desktop: 186, mobile: 80 },
//   { month: "February", desktop: 305, mobile: 200 },
//   { month: "March", desktop: 237, mobile: 120 },
//   { month: "April", desktop: 73, mobile: 190 },
//   { month: "May", desktop: 209, mobile: 130 },
//   { month: "June", desktop: 214, mobile: 140 },
// ];

// const chartConfig = {
//   desktop: {
//     label: "Desktop",
//     color: "hsl(var(--chart-1))",
//   },
//   mobile: {
//     label: "Mobile",
//     color: "hsl(var(--chart-2))",
//   },
// } satisfies ChartConfig;

// export const RunTime = () => {
//   return (
//     <Card>
//       <CardHeader>
//         <CardTitle>Line Chart - Multiple</CardTitle>
//         <CardDescription>January - June 2024</CardDescription>
//       </CardHeader>
//       <CardContent className="h-[fit] max-h-[450px] p-7.5">
//         <ChartContainer config={chartConfig} className="h-[350px]">
//           <LineChart
//             accessibilityLayer
//             data={chartData}
//             margin={{
//               left: 12,
//               right: 12,
//             }}
//           >
//             <CartesianGrid vertical={false} />
//             <XAxis
//               dataKey="month"
//               tickLine={false}
//               axisLine={false}
//               tickMargin={8}
//               tickFormatter={(value) => value.slice(0, 3)}
//             />
//             <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
//             <Line
//               dataKey="desktop"
//               type="linear"
//               stroke="#121212"
//               strokeWidth={2}
//               dot={true}
//             />
//             <Line
//               dataKey="mobile"
//               type="linear"
//               stroke="#129393"
//               strokeWidth={2}
//               dot={true}
//             />
//           </LineChart>
//         </ChartContainer>
//       </CardContent>
//       <CardFooter>
//         <div className="flex w-full items-start gap-2 text-sm">
//           <div className="grid gap-2">
//             <div className="flex items-center gap-2 font-medium leading-none">
//               Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
//             </div>
//             <div className="flex items-center gap-2 leading-none text-muted-foreground">
//               Showing total visitors for the last 6 months
//             </div>
//           </div>
//         </div>
//       </CardFooter>
//     </Card>
//   );
// };
