"use client";

import { useEffect, useState } from "react";
import { Button } from "../../third-party/shadcn/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../third-party/shadcn/select";
import { GenericModal } from "../shared/Modal";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirm: (month?: string) => void;
  allowMonthSelect?: boolean;
}

export const WorkflowTriggerModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  allowMonthSelect = false,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const handleConfirm = async () => {
    setDisabled(true);
    if (allowMonthSelect) { 
      onConfirm(selectedPeriod);
      return;
    }
    
    onConfirm();
  };

  useEffect(() => {
    setDisabled(false);
  }, []);

  const getPeriodOptions = () => {
    const currentDate = new Date();

    const options = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const label = date.toLocaleString("default", { month: "long" });
      options.push({ value: date.getMonth() + 1, label });
    }

    return options;
  };

  useEffect(() => {
    const options = getPeriodOptions();
    setPeriodOptions(options);
    setSelectedPeriod(options[0].value);
  }, []);

  const modalMonthSelect = (
    <>
      <Select value={selectedPeriod} onValueChange={setSelectedPeriod}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select period" />
        </SelectTrigger>
        <SelectContent>
          {periodOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );

  const modalFooter = (
    <>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button onClick={handleConfirm} disabled={disabled}>
        Confirm Run
      </Button>
    </>
  );

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title="Trigger Integration"
      icon={
        <img
          src="/assets/danger.png"
          width={18}
          height={18}
          alt="Danger icon"
        />
      }
      footer={modalFooter}
    >
      <p className="mb-4">
        Are you sure you want to re-run this integration? It cannot be stopped
        once started.
      </p>
      {allowMonthSelect && modalMonthSelect}
    </GenericModal>
  );
};
